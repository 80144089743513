<template>
  <div id="booking" class="Content-Page">
    <div id="flightDetail" class="wrap-Main">

      <div class="box-S1">
        <div class="box-S4">
          <div class="N-Page T-size-24">Pilot Information</div>
        </div>
        <div class="B-carddetail B-formMain">
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_prefix" label="Title" disabled></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_name" label="Full Name" disabled></v-text-field>
            </div>
          </div>
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_short_name" label="Short Name" disabled></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field v-model="formModel.pilot_rank" label="Rank" type="number" disabled></v-text-field>
            </div>
          </div>
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_weight_kg" label="Weight (kg)" disabled v-on:keyup="ConvertKgToLb()"
                type="number"></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_weight_lb" label="Weight (lb)" type="number" disabled></v-text-field>
            </div>
          </div>
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_id_card_no" label="ID Card No." type="number" disabled></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_position" label="Position" disabled></v-text-field>
            </div>
          </div>
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-model="formModel.pl_department" label="Department" disabled></v-text-field>
            </div>
            <div class="box-S2">
              <v-select class="hideMessage" :items="itemsListGender" label="Gender" disabled
                v-model="formModel.pl_gender"></v-select>
            </div>
          </div>

        </div>
      </div>

      <div class="box-S3">
        <div class="box-S4">
          <div class="N-Page T-size-24">Pilot Experience Summary</div>
        </div>

        <div class="B-carddetail B-formMain">
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-on:keyup="ValidationSpecialty()" v-model="formModelSpecialty.fom_spc_IFR_IMC_min"
                label="IFR (IMC) (hh:mm)"></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field v-on:keyup="ValidationSpecialty()" v-model="formModelSpecialty.fom_spc_night_min"
                label="Night (hh:mm)"></v-text-field>
            </div>
          </div>
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-on:keyup="ValidationSpecialty()" v-model="formModelSpecialty.fom_spc_offshore_min"
                label="Offshore (hh:mm)"></v-text-field>
            </div>
            <div class="box-S2">
              <v-text-field v-on:keyup="ValidationSpecialty()" v-model="formModelSpecialty.fom_spc_tri_min"
                label="TRI (hh:mm)"></v-text-field>
            </div>
          </div>
          <div class="b-row">
            <div class="box-S2">
              <v-text-field v-on:keyup="ValidationSpecialty()" v-model="formModelSpecialty.fom_spc_tre_min"
                label="TRE (hh:mm)"></v-text-field>
            </div>
            <div class="box-S2">
              <v-menu v-model="menuselect_cutoff_date" :close-on-content-click="false" transition="scale-transition"
                offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field class="hideMessage input-date" v-model="formModelSpecialty.fom_spc_cutoff_date"
                    label="Cutoff Date" readonly v-on="on" append-icon="mdi-calendar-range"></v-text-field>
                </template>
                <v-date-picker v-model="formModelSpecialty.fom_spc_cutoff_date"
                  @input="menuselect_cutoff_date = false"></v-date-picker>
              </v-menu>
            </div>
          </div>
        </div>
        <br />
        <div v-show="!validationSpecialty" style="background-color: white; border-radius: 15px; height: 30px;">
          <p style="text-align: center; color: black; font-size: 20px;">
            <b>Please check if the input format hours:minutes</b>
          </p>
        </div>

        <div class="box-S4">
          <div class="b-btnSave">
            <v-btn class="theme-btn-even btn-cancle Bsize150" @click="GotoManagePilot()">
              <span class="T-size-18">Cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize150" @click="saveToServer()" v-show="validationSpecialty">
              <span class="I-save"></span>
              <span class="T-size-18">Save</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div id="flightDetail" class="wrap-Main">
      <div class="box-S4" style="display: flex; justify-content: space-between;">
        <div style="padding-top: 10px;">
          <small>Note: PIC = Pilot-in-Command / Captain SIC = Second-in-Command / Co-pilot</small>
        </div>
        <div>
          <v-btn class="theme-btn-even" @click.stop="addItem()">
            <span class="I-create"></span>
            <span>Add pilot experience</span>
          </v-btn>
        </div>
      </div>

      <div class="box-S4">
        <v-data-table :headers="headers" :items="listPilotExp">
          <template v-slot:item.action="{ item }" class="text-xs-right">
            <div class="b-action">
              <div class="b-btn">
                <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>

      <!-- <div class="box-S1">
        <div class="box-S4">
          <div class="B-destination-Main">
            <div class="B-destination" v-for="(item, index) in listPilotExp" :key="item.fom_spc_id">
              <div class="b-destination-name">
                <div class="T-size-18 SemiBold">Model : {{ item.aircraftModel.ac_model }}
                  <br />{{ item.aircraftModel.fom_acm_wing }} ({{ item.aircraftModel.fom_acm_engine }})
                </div>
                <div class="T-size-18 SemiBold">
                  PIC : {{ item.fom_pex_pic }}
                  <spane v-if="item.aircraftModel.fom_acm_wing == 'Rotary-Wing'"><br />PICUS : {{ item.fom_pex_picus }}
                  </spane>
                  <br />SIC : {{ item.fom_pex_sic }}
                </div>
                <div class="T-size-18 SemiBold">Toyal Type : {{ item.fom_pex_total_type }}</div>
              </div>

              <div class="b-action">
                <div class="b-btn">
                  <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                    <span class="I-bin"></span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even" @click.stop="addItem()">
                <span class="I-create"></span>
                <span>Add</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div> -->

      <div class="box-S4">
        <div class="B-destination-Main" style="margin-top: 11px;">
          <div class="flex-container">
            <div class="box-S2">
              <table style="border: solid;">
                <tbody>
                  <tr>
                    <td class="th" rowspan="2"><i>Rotary-Wing</i> (S-Engine)</td>
                    <td class="th" colspan="3"><i>Hours</i></td>
                    <td class="th" rowspan="2"><i>Total Type</i></td>
                  </tr>
                  <tr>
                    <td class="th"><i>PIC</i></td>
                    <td class="th"><i>PICUS</i></td>
                    <td class="th"><i>SIC</i></td>
                  </tr>
                  <tr v-for="(item, index) in rotaryWingSEngine" :key="index">
                    <td v-if="item.aircraftModel.ac_model != '-'" style="text-align: left;">{{
                item.aircraftModel.ac_model
              }}
                    </td>
                    <td v-if="item.aircraftModel.ac_model == '-'" style="text-align: left; color:white;">{{
                item.aircraftModel.ac_model }}</td>
                    <td>{{ item.fom_pex_pic }}</td>
                    <td>{{ item.fom_pex_picus }}</td>
                    <td>{{ item.fom_pex_sic }}</td>
                    <td>{{ item.fom_pex_total_type }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left;">Total Single</td>
                    <td style="background-color: yellow; font-weight: bold;"><b>{{ sumRotaryWingSEnginePIC }}</b></td>
                    <td style="background-color: yellow; font-weight: bold;"><b>{{ sumRotaryWingSEnginePICUS }}</b></td>
                    <td style="background-color: #00b0f0; font-weight: bold;"><b>{{ sumRotaryWingSEngineSIC }}</b></td>
                    <td style="background-color: #bfbfbf; font-weight: bold;"><b>{{ sumRotaryWingSEngineTotalType }}</b>
                    </td>
                  </tr>
                  <!-- Rotary Wing (Multi-Engine) -->
                  <tr>
                    <td class="th" rowspan="2"><i>Rotary-Wing</i>(Multi-Engine)</td>
                    <td class="th" colspan="3"><i>Hours</i></td>
                    <td class="th" rowspan="2">Total Type</td>
                  </tr>
                  <tr>
                    <td class="th"><i>PIC</i></td>
                    <td class="th"><i>PICUS</i></td>
                    <td class="th"><i>SIC</i></td>
                  </tr>
                  <tr v-for="(item, index) in rotaryWingMultiEngine
            " :key="index">
                    <td v-if="item.aircraftModel.ac_model != '-'" style="text-align: left;">{{
                item.aircraftModel.ac_model
              }}</td>
                    <td v-if="item.aircraftModel.ac_model == '-'" style="text-align: left; color:white;">{{
                item.aircraftModel.ac_model }}</td>
                    <td>{{ item.fom_pex_pic }}</td>
                    <td>{{ item.fom_pex_picus }}</td>
                    <td>{{ item.fom_pex_sic }}</td>
                    <td>{{ item.fom_pex_total_type }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left;">Total Multi</td>
                    <td style="background-color: yellow;">{{ sumRotaryWingMultiEnginePIC }}</td>
                    <td style="background-color: yellow;">{{ sumRotaryWingMultiEnginePICUS }}</td>
                    <td style="background-color: #00b0f0;">{{ sumRotaryWingMultiEngineSIC }}</td>
                    <td style="background-color: #bfbfbf; font-weight: bold;"><b>{{ sumRotaryWingMultiEngineTotalType
                        }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-weight: bold;">Total Pic & Sic</td>
                    <td style="font-weight: bold;"><b>{{ sumRotaryWingPIC }}</b></td>
                    <td style="font-weight: bold;"><b>{{ sumRotaryWingPICUS }}</b></td>
                    <td style="font-weight: bold;"><b>{{ sumRotaryWingSIC }}</b></td>
                    <td style="background-color: #404040;"></td>
                  </tr>
                  <tr>
                    <td colspan="4" class="th" style="text-align: center; font-weight: bold;">Total of Helicopter</td>
                    <td style="font-weight: bold;"><b>{{ totalOfHelicopter }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Fixed-Wing -->
            <div class="box-S2">
              <table style="border: solid;">
                <tbody>
                  <!-- Fixed-Wing (S-Engine) -->
                  <tr>
                    <td class="th" rowspan="2"><i>Fixed-Wing</i> (S-Engine)</td>
                    <td class="th" colspan="2"><i>Hours</i></td>
                    <td class="th" rowspan="2"><i>Total Type</i></td>
                  </tr>
                  <tr>
                    <td class="th"><i>PIC</i></td>
                    <td class="th"><i>SIC</i></td>
                  </tr>
                  <tr v-for="(item, index) in fixedWingSEngine" :key="index">
                    <td v-if="item.aircraftModel.ac_model != '-'" style="text-align: left;">{{
                item.aircraftModel.ac_model
              }}
                    </td>
                    <td v-if="item.aircraftModel.ac_model == '-'" style="text-align: left; color:white;">{{
                item.aircraftModel.ac_model }}</td>
                    <td>{{ item.fom_pex_pic }}</td>
                    <td>{{ item.fom_pex_sic }}</td>
                    <td>{{ item.fom_pex_total_type }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left;">Total Single</td>
                    <td style="background-color: yellow; font-weight: bold;"><b>{{ sumFixedWingSEnginePIC }}</b></td>
                    <td style="background-color: #00b0f0; font-weight: bold;"><b>{{ sumFixedWingSEngineSIC }}</b></td>
                    <td style="background-color: #bfbfbf; font-weight: bold;"><b>{{ sumFixedWingSEngineTotalType }}</b>
                    </td>
                  </tr>
                  <!-- Fixed-Wing (S-Engine) -->
                  <!-- Fixed-Wing (Multi-Engine) -->
                  <tr>
                    <td class="th" rowspan="2"><i>Fixed-Wing</i>(Multi-Engine)</td>
                    <td class="th" colspan="2"><i>Hours</i></td>
                    <td class="th" rowspan="2">Total Type</td>
                  </tr>
                  <tr>
                    <td class="th"><i>PIC</i></td>
                    <td class="th"><i>SIC</i></td>
                  </tr>
                  <tr v-for="(item, index) in fixedWingMultiEngine" :key="index">
                    <td v-if="item.aircraftModel.ac_model != '-'" style="text-align: left;">{{
                item.aircraftModel.ac_model
              }}
                    </td>
                    <td v-if="item.aircraftModel.ac_model == '-'" style="text-align: left; color:white;">{{
                item.aircraftModel.ac_model }}</td>
                    <td>{{ item.fom_pex_pic }}</td>
                    <td>{{ item.fom_pex_sic }}</td>
                    <td>{{ item.fom_pex_total_type }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left;">Total Multi</td>
                    <td style="background-color: yellow;">{{ sumFixedWingMultiEnginePIC }}</td>
                    <td style="background-color: #00b0f0;">{{ sumFixedWingMultiEngineSIC }}</td>
                    <td style="background-color: #bfbfbf; font-weight: bold;"><b>{{ sumFixedWingMultiEngineTotalType
                        }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-weight: bold;">Total Pic & Sic</td>
                    <td style="font-weight: bold;"><b>{{ sumFixedWingPIC }}</b></td>
                    <td style="font-weight: bold;"><b>{{ sumFixedWingSIC }}</b></td>
                    <td style="background-color: #404040;"></td>
                  </tr>
                  <tr>
                    <td colspan="3" class="th" style="text-align: center; font-weight: bold;">Total of Fixed-wing</td>
                    <td style="font-weight: bold;"><b>{{ totalOfFixedWing }}</b></td>
                  </tr>
                  <tr>
                    <td colspan="2" class="th" style="text-align: center; font-weight: bold; font-size:25px">Grand Total
                      Hours</td>
                    <td colspan="2" class="th" style="text-align: center; background-color: #00B050; font-size:30px">
                      <b>{{
                grandTotalHours }}</b>
                    </td>
                  </tr>
                  <!-- Fixed-Wing (Multi-Engine) -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogSaveSuccess" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="SaveSuccess()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับ เพิ่ม แก้ไข -->
    <v-dialog v-model="dialogAddPilotEx" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-24 TF">Pilot experience</div>
          </div>
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <v-select :items="itemsAircraftModel" v-model="formModelPex.fomAircraftModel" label="Aircraft Model"
                item-text="ac_model" item-value="fom_acm_id" return-object @change="CalTotalType()"></v-select>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-text-field v-on:keyup="ValidationFlyingExperience()" v-model="formModelPex.fom_pex_pic"
                  label="PIC (hh:mm)" @input="CalTotalType()"></v-text-field>
              </div>
            </div>
            <div class="b-row" v-show="formModelPex.fomAircraftModel.fom_acm_wing == 'Rotary-Wing'">
              <div class="box-S4">
                <v-text-field v-on:keyup="ValidationFlyingExperience()" v-model="formModelPex.fom_pex_picus"
                  label="PICUS (hh:mm)" @input="CalTotalType()"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-text-field v-on:keyup="ValidationFlyingExperience()" v-model="formModelPex.fom_pex_sic"
                  label="SIC (hh:mm)" @input="CalTotalType()"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-text-field v-model="formModelPex.fom_pex_total_type" label="Total Type" disabled></v-text-field>
              </div>
            </div>
            <div v-show="!validationFlyingExperience"
              style="background-color: white; border-radius: 15px; height: 20px;">
              <p style="text-align: center; color: black; font-size: 14px;">
                <b>Please check if the input format hours:minutes</b>
              </p>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogAddPilotEx = false">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn class="theme-btn-even btn-save Bsize100" text v-show="validationFlyingExperience"
                @click="SavePilotExpToServer()">
                <span class="T-size-18">save</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog delete">
            <div class="t-title">Confirm Deletion</div>
            <div class="t-des">
              Are you sure to permanently delete this item?
              <div>Please type "YES" to confirm.</div>
              <div class="B-carddetail B-formMain">
                <div class="b-row">
                  <div class="box-S4">
                    <v-text-field class="hideMessage" label="Confirm" type="text" v-model="confirmDelete">
                    </v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-S4"></div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="OpenDialogDeleteFDT = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-delete Bsize100" text @click="DeleteFlightDutyTime()">
              <span class="T-size-18">Delete</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteSuccess" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Delete Completed</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="deleteSuccess()">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.b-row .box-S2 {
  margin: 0px 0px;
}
</style>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  data: () => ({
    headers: [
      { value: "aircraftModel.ac_model", text: "Aircraft Model", sortable: true },
      { value: "aircraftModel.fom_acm_wing", text: "Wing Type", sortable: true },
      { value: "aircraftModel.fom_acm_engine", text: "Engine Type", sortable: true },
      { value: "fom_pex_pic", text: "PIC", sortable: true },
      { value: "fom_pex_picus", text: "PICUS (Only Rotary-Wing)", sortable: true },
      { value: "fom_pex_sic", text: "SIC", sortable: true },
      { value: "fom_pex_total_type", text: "Total Type", sortable: true },
      { value: "action", text: "", sortable: false }
    ],
    dialogDeleteSuccess: false,
    pl_id: null,
    defaultValue: {
      pl_prefix: "",
      pl_name: "",
      pl_short_name: "",
      pilot_rank: null,
      pl_weight_kg: 0,
      pl_weight_lb: 0,
      pl_weight_last_update: new Date(),
      pl_id_card_no: null,
      pl_position: "",
      pl_department: "",
      pl_gender: "Male",
    },
    query: { $sort: { Id: -1 } },
    //--end config

    items: [],
    loading: false,
    dialogAddPilotEx: false,
    dialogDelete: false,
    formModel: {
      pl_gender: "Male"
    },
    stepdialog: 1,
    itemsListGender: ["Male", "Fremale"],

    dialogSaveSuccess: false,
    listPilotExp: [],

    formModelSpecialty: {
      fom_spc_IFR_IMC_min: null,
      fom_spc_night_min: null,
      fom_spc_offshore_min: null,
      fom_spc_tri_min: null,
      fom_spc_tre_min: null,
    },
    menuselect_cutoff_date: false,
    validateIMC: false,
    validateNight: false,
    validateOffshore: false,

    itemsAircraftModel: [],
    formModelPex: {
      fomAircraftModel: {
        fom_acm_id: 1,
        ac_model: "",
        fom_acm_wing: ""
      },
      fom_pex_pic: null,
      fom_pex_picus: null,
      fom_pex_sic: null,
      fom_pex_total_type: null,
    },

    validationSpecialty: false,
    validationFlyingExperience: false,

    //Rrotary Wing
    rotaryWingSEngine: [],
    rotaryWingMultiEngine: [],
    sumRotaryWingSEnginePIC: "00:00",
    sumRotaryWingSEnginePICUS: "00:00",
    sumRotaryWingSEngineSIC: "00:00",
    sumRotaryWingSEngineTotalType: "00:00",

    rotaryWingMultiEngine: [],
    sumRotaryWingMultiEnginePIC: "00:00",
    sumRotaryWingMultiEnginePICUS: "00:00",
    sumRotaryWingMultiEngineSIC: "00:00",
    sumRotaryWingMultiEngineTotalType: "00:00",

    sumRotaryWingPIC: "00:00",
    sumRotaryWingPICUS: "00:00",
    sumRotaryWingSIC: "00:00",
    totalOfHelicopter: "00:00",
    //Rrotary Wing

    //Fixed Wing
    fixedWingSEngine: [],
    fixedWingMultiEngine: [],
    sumFixedWingSEnginePIC: "00:00",
    sumFixedWingSEnginePICUS: "00:00",
    sumFixedWingSEngineSIC: "00:00",
    sumFixedWingSEngineTotalType: "00:00",

    rotaryWingMultiEngine: [],
    sumFixedWingMultiEnginePIC: "00:00",
    sumFixedWingMultiEnginePICUS: "00:00",
    sumFixedWingMultiEngineSIC: "00:00",
    sumFixedWingMultiEngineTotalType: "00:00",

    sumFixedWingPIC: "00:00",
    sumFixedWingPICUS: "00:00",
    sumFixedWingSIC: "00:00",
    totalOfFixedWing: "00:00",
    //Fixed Wing

    grandTotalHours: "00:00",
  }),
  computed: {

  },
  async mounted() {
    this.pl_id = this.$route.params.id;
    //init here
    await this.renderUI();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        const q = {};
        q.pl_id = this.pl_id
        q.retire = 0;
        var resPilot = await feathersClientUOA.service("pilot").find({ query: q });
        this.formModel = Object.assign({}, resPilot.data[0]);

        const qFdt = {};
        qFdt.pl_id = this.pl_id
        var resPDT = await feathersClientUOA.service("fom-pilot-flight-duty-time").find({ query: qFdt });

        if (resPDT.total > 0) {
          this.formModelSpecialty = {
            fom_spc_IFR_IMC_min: this.$ConvertService.MinToHHmm(resPDT.data[0].fom_spc_IFR_IMC_min),
            fom_spc_night_min: this.$ConvertService.MinToHHmm(resPDT.data[0].fom_spc_night_min),
            fom_spc_offshore_min: this.$ConvertService.MinToHHmm(resPDT.data[0].fom_spc_offshore_min),
            fom_spc_tri_min: this.$ConvertService.MinToHHmm(resPDT.data[0].fom_spc_tri_min),
            fom_spc_tre_min: this.$ConvertService.MinToHHmm(resPDT.data[0].fom_spc_tre_min),
            fom_spc_cutoff_date: resPDT.data[0].fom_spc_cutoff_date.substring(0, 10),
          }
        } else {
          this.formModelSpecialty = {
            fom_spc_IFR_IMC_min: "00:00",
            fom_spc_night_min: "00:00",
            fom_spc_offshore_min: "00:00",
            fom_spc_tri_min: "00:00",
            fom_spc_tre_min: "00:00",
            fom_spc_cutoff_date: "",
          }
        }
        this.ValidationSpecialty();

        this.listPilotExp = [];
        const qPex = {};
        qPex.pl_id = this.pl_id
        qPex.$eager = "[aircraftModel]";
        var resPEX = await feathersClientUOA.service("fom-pilot-experience").find({ query: qPex });
        if (resPEX.total > 0) {
          resPEX.data.forEach(item => {
            let newObj = {};
            newObj.fom_pex_id = item.fom_pex_id;
            newObj.aircraftModel = item.aircraftModel;
            console.log(item.fom_pex_pic_mi)
            newObj.fom_pex_pic = (item.fom_pex_pic > 0 ? this.$ConvertService.MinToHHmm(item.fom_pex_pic) : "");
            newObj.fom_pex_pic_min = item.fom_pex_pic;
            newObj.fom_pex_picus = (item.fom_pex_picus > 0 ? this.$ConvertService.MinToHHmm(item.fom_pex_picus) : "");
            newObj.fom_pex_picus_min = item.fom_pex_picus;
            newObj.fom_pex_sic = (item.fom_pex_sic > 0 ? this.$ConvertService.MinToHHmm(item.fom_pex_sic) : "");
            newObj.fom_pex_sic_min = item.fom_pex_sic;
            newObj.fom_pex_total_type = (item.fom_pex_total_type > 0 ? this.$ConvertService.MinToHHmm(item.fom_pex_total_type) : "");
            newObj.fom_pex_total_type_min = item.fom_pex_total_type;
            this.listPilotExp.push(newObj);
          });

          //Rotary Wing
          this.rotaryWingSEngine = [];
          let rotaryWingSEngine = [];
          let sumRotaryWingSEnginePIC = 0;
          let sumRotaryWingSEnginePICUS = 0;
          let sumRotaryWingSEngineSIC = 0;
          let sumRotaryWingSEngineTotalType = 0;
          rotaryWingSEngine = this.listPilotExp.filter(x => x.aircraftModel.fom_acm_wing == "Rotary-Wing" && x.aircraftModel.fom_acm_engine == "S-Engine");
          for (let i = 0; i < 8; i++) {
            if (rotaryWingSEngine[i] != undefined) {
              this.rotaryWingSEngine.push(rotaryWingSEngine[i]);
              sumRotaryWingSEnginePIC += rotaryWingSEngine[i].fom_pex_pic_min;
              sumRotaryWingSEnginePICUS += rotaryWingSEngine[i].fom_pex_picus_min;
              sumRotaryWingSEngineSIC += rotaryWingSEngine[i].fom_pex_sic_min;
              sumRotaryWingSEngineTotalType += rotaryWingSEngine[i].fom_pex_total_type_min;
            } else {
              this.rotaryWingSEngine.push({
                "fom_pex_id": 0,
                "aircraftModel": {
                  "fom_acm_id": 0,
                  "ac_model": "-",
                  "fom_acm_wing": "",
                  "fom_acm_engine": ""
                },
                "fom_pex_pic": "",
                "fom_pex_picus": "",
                "fom_pex_sic": "",
                "fom_pex_total_type": ""
              })
            }
          }
          this.sumRotaryWingSEnginePIC = this.$ConvertService.MinToHHmm(sumRotaryWingSEnginePIC);
          this.sumRotaryWingSEnginePICUS = this.$ConvertService.MinToHHmm(sumRotaryWingSEnginePICUS);
          this.sumRotaryWingSEngineSIC = this.$ConvertService.MinToHHmm(sumRotaryWingSEngineSIC);
          this.sumRotaryWingSEngineTotalType = this.$ConvertService.MinToHHmm(sumRotaryWingSEngineTotalType);



          this.rotaryWingMultiEngine = [];
          let rotaryWingMultiEngine = [];
          let sumRotaryWingMultiEnginePIC = 0;
          let sumRotaryWingMultiEnginePICUS = 0;
          let sumRotaryWingMultiEngineSIC = 0;
          let sumRotaryWingMultiEngineTotalType = 0;
          rotaryWingMultiEngine = this.listPilotExp.filter(x => x.aircraftModel.fom_acm_wing == "Rotary-Wing" && x.aircraftModel.fom_acm_engine == "Multi-Engine");
          for (let i = 0; i < 7; i++) {
            if (rotaryWingMultiEngine[i] != undefined) {
              this.rotaryWingMultiEngine.push(rotaryWingMultiEngine[i]);
              sumRotaryWingMultiEnginePIC += rotaryWingMultiEngine[i].fom_pex_pic_min;
              sumRotaryWingMultiEnginePICUS += rotaryWingMultiEngine[i].fom_pex_picus_min;
              sumRotaryWingMultiEngineSIC += rotaryWingMultiEngine[i].fom_pex_sic_min;
              sumRotaryWingMultiEngineTotalType += rotaryWingMultiEngine[i].fom_pex_total_type_min;
            } else {
              this.rotaryWingMultiEngine.push({
                "fom_pex_id": 0,
                "aircraftModel": {
                  "fom_acm_id": 0,
                  "ac_model": "-",
                  "fom_acm_wing": "",
                  "fom_acm_engine": ""
                },
                "fom_pex_pic": "",
                "fom_pex_picus": "",
                "fom_pex_sic": "",
                "fom_pex_total_type": ""
              })
            }
          }
          this.sumRotaryWingMultiEnginePIC = this.$ConvertService.MinToHHmm(sumRotaryWingMultiEnginePIC);
          this.sumRotaryWingMultiEnginePICUS = this.$ConvertService.MinToHHmm(sumRotaryWingMultiEnginePICUS);
          this.sumRotaryWingMultiEngineSIC = this.$ConvertService.MinToHHmm(sumRotaryWingMultiEngineSIC);
          this.sumRotaryWingMultiEngineTotalType = this.$ConvertService.MinToHHmm(sumRotaryWingMultiEngineTotalType);

          this.sumRotaryWingPIC = this.$ConvertService.MinToHHmm(sumRotaryWingSEnginePIC + sumRotaryWingMultiEnginePIC);
          this.sumRotaryWingPICUS = this.$ConvertService.MinToHHmm(sumRotaryWingSEnginePICUS + sumRotaryWingMultiEnginePICUS);
          this.sumRotaryWingSIC = this.$ConvertService.MinToHHmm(sumRotaryWingSEngineSIC + sumRotaryWingMultiEngineSIC);
          this.totalOfHelicopter = this.$ConvertService.MinToHHmm(sumRotaryWingSEngineTotalType + sumRotaryWingMultiEngineTotalType);
          //Rotary Wing

          //Fixed Wing
          this.fixedWingSEngine = [];
          let fixedWingSEngine = [];
          let sumFixedWingSEnginePIC = 0;
          let sumFixedWingSEnginePICUS = 0;
          let sumFixedWingSEngineSIC = 0;
          let sumFixedWingSEngineTotalType = 0;
          fixedWingSEngine = this.listPilotExp.filter(x => x.aircraftModel.fom_acm_wing == "Fixed-Wing" && x.aircraftModel.fom_acm_engine == "S-Engine");
          for (let i = 0; i < 8; i++) {
            if (fixedWingSEngine[i] != undefined) {
              this.fixedWingSEngine.push(fixedWingSEngine[i]);
              sumFixedWingSEnginePIC += fixedWingSEngine[i].fom_pex_pic_min;
              sumFixedWingSEnginePICUS += fixedWingSEngine[i].fom_pex_picus_min;
              sumFixedWingSEngineSIC += fixedWingSEngine[i].fom_pex_sic_min;
              sumFixedWingSEngineTotalType += fixedWingSEngine[i].fom_pex_total_type_min;
            } else {
              this.fixedWingSEngine.push({
                "fom_pex_id": 0,
                "aircraftModel": {
                  "fom_acm_id": 0,
                  "ac_model": "-",
                  "fom_acm_wing": "",
                  "fom_acm_engine": ""
                },
                "fom_pex_pic": "",
                "fom_pex_picus": "",
                "fom_pex_sic": "",
                "fom_pex_total_type": ""
              })
            }
          }
          this.sumFixedWingSEnginePIC = this.$ConvertService.MinToHHmm(sumFixedWingSEnginePIC);
          this.sumFixedWingSEnginePICUS = this.$ConvertService.MinToHHmm(sumFixedWingSEnginePICUS);
          this.sumFixedWingSEngineSIC = this.$ConvertService.MinToHHmm(sumFixedWingSEngineSIC);
          this.sumFixedWingSEngineTotalType = this.$ConvertService.MinToHHmm(sumFixedWingSEngineTotalType);


          this.fixedWingMultiEngine = [];
          let fixedWingMultiEngine = [];
          let sumFixedWingMultiEnginePIC = 0;
          let sumFixedWingMultiEnginePICUS = 0;
          let sumFixedWingMultiEngineSIC = 0;
          let sumFixedWingMultiEngineTotalType = 0;
          fixedWingMultiEngine = this.listPilotExp.filter(x => x.aircraftModel.fom_acm_wing == "Fixed-Wing" && x.aircraftModel.fom_acm_engine == "Multi-Engine");
          for (let i = 0; i < 7; i++) {
            if (fixedWingMultiEngine[i] != undefined) {
              this.fixedWingMultiEngine.push(fixedWingMultiEngine[i]);
              sumFixedWingMultiEnginePIC += fixedWingMultiEngine[i].fom_pex_pic_min;
              sumFixedWingMultiEnginePICUS += fixedWingMultiEngine[i].fom_pex_picus_min;
              sumFixedWingMultiEngineSIC += fixedWingMultiEngine[i].fom_pex_sic_min;
              sumFixedWingMultiEngineTotalType += fixedWingMultiEngine[i].fom_pex_total_type_min;
            } else {
              this.fixedWingMultiEngine.push({
                "fom_pex_id": 0,
                "aircraftModel": {
                  "fom_acm_id": 0,
                  "ac_model": "-",
                  "fom_acm_wing": "",
                  "fom_acm_engine": ""
                },
                "fom_pex_pic": "",
                "fom_pex_picus": "",
                "fom_pex_sic": "",
                "fom_pex_total_type": ""
              })
            }
          }

          this.sumFixedWingMultiEnginePIC = this.$ConvertService.MinToHHmm(sumFixedWingMultiEnginePIC);
          this.sumFixedWingMultiEnginePICUS = this.$ConvertService.MinToHHmm(sumFixedWingMultiEnginePICUS);
          this.sumFixedWingMultiEngineSIC = this.$ConvertService.MinToHHmm(sumFixedWingMultiEngineSIC);
          this.sumFixedWingMultiEngineTotalType = this.$ConvertService.MinToHHmm(sumFixedWingMultiEngineTotalType);

          this.sumFixedWingPIC = this.$ConvertService.MinToHHmm(sumFixedWingSEnginePIC + sumFixedWingMultiEnginePIC);
          this.sumFixedWingPICUS = this.$ConvertService.MinToHHmm(sumFixedWingSEnginePICUS + sumFixedWingMultiEnginePICUS);
          this.sumFixedWingSIC = this.$ConvertService.MinToHHmm(sumFixedWingSEngineSIC + sumFixedWingMultiEngineSIC);
          this.totalOfFixedWing = this.$ConvertService.MinToHHmm(sumFixedWingSEngineTotalType + sumFixedWingMultiEngineTotalType);
          //Fixed Wing

          this.grandTotalHours = this.$ConvertService.MinToHHmm((sumRotaryWingSEngineTotalType + sumRotaryWingMultiEngineTotalType) + (sumFixedWingSEngineTotalType + sumFixedWingMultiEngineTotalType));

        }
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async saveToServer() {
      this.loading = true;
      try {
        const q = {};
        q.pl_id = this.pl_id
        let res = await feathersClientUOA.service("fom-pilot-flight-duty-time").find({ query: q })
        if (res.total == 0) {
          let dataAdd = {
            pl_id: this.pl_id,
            fom_spc_IFR_IMC_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_IFR_IMC_min),
            fom_spc_night_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_night_min),
            fom_spc_offshore_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_offshore_min),
            fom_spc_tri_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_tri_min),
            fom_spc_tre_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_tre_min),
            fom_spc_cutoff_date: this.formModelSpecialty.fom_spc_cutoff_date,
          };
          await feathersClientUOA.service("fom-pilot-flight-duty-time").create(dataAdd);
          this.formModelSpecialty = {};
          this.renderUI();
          this.dialogSaveSuccess = true;
        } else {
          let dataEdit = {
            fom_spc_IFR_IMC_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_IFR_IMC_min),
            fom_spc_night_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_night_min),
            fom_spc_offshore_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_offshore_min),
            fom_spc_tri_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_tri_min),
            fom_spc_tre_min: this.$ConvertService.HHmmToMin(this.formModelSpecialty.fom_spc_tre_min),
            fom_spc_cutoff_date: this.formModelSpecialty.fom_spc_cutoff_date,
          };
          await feathersClientUOA.service("fom-pilot-flight-duty-time").patch(res.data[0].fom_spc_id, dataEdit);
          this.formModelSpecialty = {};
          this.renderUI();
          this.dialogSaveSuccess = true;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    ConvertKgToLb() {
      this.formModel.pl_weight_lb = null;
      this.formModel.pl_weight_lb = (this.formModel.pl_weight_kg * 2.2046).toFixed(2);
    },
    GotoManagePilot() {
      this.$router.go(-1);
    },

    SaveSuccess() {
      this.renderUI();
      this.dialogSaveSuccess = false;
    },

    async addItem() {
      this.formModelPex = {
        fomAircraftModel: {
          fom_acm_id: 1,
          ac_model: "",
          fom_acm_wing: ""
        },
        fom_pex_pic: "00:00",
        fom_pex_picus: "00:00",
        fom_pex_sic: "00:00",
        fom_pex_total_type: "00:00",
      };

      this.itemsAircraftModel = [];
      var resAirCraftModel = await feathersClientUOA.service("fom-aircraft-model").find({
        query: {
          $sort: {
            ac_model: 1
          }
        }
      });
      resAirCraftModel.data.forEach(item => {
        let newObj = {};
        newObj.fom_acm_id = item.fom_acm_id;
        newObj.ac_model = item.ac_model + " : " + item.fom_acm_wing + " (" + item.fom_acm_engine + ")";
        newObj.fom_acm_wing = item.fom_acm_wing;
        this.itemsAircraftModel.push(newObj);
      });
      this.formModelPex.fomAircraftModel = this.itemsAircraftModel[0];
      this.ValidationFlyingExperience();
      this.dialogAddPilotEx = true;
      console.log(JSON.stringify(this.itemsAircraftModel))
    },
    async deleteItem(item) {
      try {
        await feathersClientUOA.service("fom-pilot-experience").remove(item.fom_pex_id);
        this.renderUI();
        this.dialogDeleteSuccess = true;
      } catch (err) {
        console.log(err);
      } finally {

      }
    },
    deleteSuccess() {
      this.dialogDeleteSuccess = false;
    },
    CalTotalType() {
      let totalType = 0;
      let pic = this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_pic);
      let picus = this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_picus);
      let sic = this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_sic);
      if (isNaN(pic)) {
        pic = 0;
      }
      if (isNaN(picus)) {
        picus = 0;
      }
      if (isNaN(sic)) {
        sic = 0;
      }
      if (this.formModelPex.fomAircraftModel.fom_acm_wing == "Rotary-Wing") {
        totalType = pic + picus + sic;
      } else if (this.formModelPex.fomAircraftModel.fom_acm_wing == "Fixed-Wing") {
        totalType = pic + sic;
      }
      this.formModelPex.fom_pex_total_type = this.$ConvertService.MinToHHmm(totalType);
    },

    ValidationSpecialty() {
      const regex = /[^0-9:]/g;
      if (this.formModelSpecialty.fom_spc_IFR_IMC_min != null) {
        this.formModelSpecialty.fom_spc_IFR_IMC_min = this.formModelSpecialty.fom_spc_IFR_IMC_min.replace(regex, '');
      }
      if (this.formModelSpecialty.fom_spc_night_min != null) {
        this.formModelSpecialty.fom_spc_night_min = this.formModelSpecialty.fom_spc_night_min.replace(regex, '');
      }
      if (this.formModelSpecialty.fom_spc_offshore_min != null) {
        this.formModelSpecialty.fom_spc_offshore_min = this.formModelSpecialty.fom_spc_offshore_min.replace(regex, '');
      }
      if (this.formModelSpecialty.fom_spc_tri_min != null) {
        this.formModelSpecialty.fom_spc_tri_min = this.formModelSpecialty.fom_spc_tri_min.replace(regex, '');
      }
      if (this.formModelSpecialty.fom_spc_tre_min != null) {
        this.formModelSpecialty.fom_spc_tre_min = this.formModelSpecialty.fom_spc_tre_min.replace(regex, '');
      }

      if (this.Validation(this.formModelSpecialty.fom_spc_IFR_IMC_min) && this.Validation(this.formModelSpecialty.fom_spc_night_min) && this.Validation(this.formModelSpecialty.fom_spc_offshore_min) && this.Validation(this.formModelSpecialty.fom_spc_tri_min) && this.Validation(this.formModelSpecialty.fom_spc_tre_min)) {
        this.validationSpecialty = true;
      } else {
        this.validationSpecialty = false;
      }
    },

    ValidationFlyingExperience() {
      const regex = /[^0-9:]/g;
      if (this.formModelPex.fomAircraftModel.fom_acm_wing == "Rotary-Wing") {
        if (this.formModelPex.fom_pex_pic != null) {
          this.formModelPex.fom_pex_pic = this.formModelPex.fom_pex_pic.replace(regex, '');
        }
        if (this.formModelPex.fom_pex_picus != null) {
          this.formModelPex.fom_pex_picus = this.formModelPex.fom_pex_picus.replace(regex, '');
        }
        if (this.formModelPex.fom_pex_sic != null) {
          this.formModelPex.fom_pex_sic = this.formModelPex.fom_pex_sic.replace(regex, '');
        }
        if (this.Validation(this.formModelPex.fom_pex_pic) && this.Validation(this.formModelPex.fom_pex_picus) && this.Validation(this.formModelPex.fom_pex_sic)) {
          this.validationFlyingExperience = true;
        } else {
          this.validationFlyingExperience = false;
        }
      } else if (this.formModelPex.fomAircraftModel.fom_acm_wing == "Fixed-Wing") {
        if (this.formModelPex.fom_pex_pic != null) {
          this.formModelPex.fom_pex_pic = this.formModelPex.fom_pex_pic.replace(regex, '');
        }
        if (this.formModelPex.fom_pex_sic != null) {
          this.formModelPex.fom_pex_sic = this.formModelPex.fom_pex_sic.replace(regex, '');
        }
        if (this.Validation(this.formModelPex.fom_pex_pic) && this.Validation(this.formModelPex.fom_pex_sic)) {
          this.validationFlyingExperience = true;
        } else {
          this.validationFlyingExperience = false;
        }
      }
    },

    Validation(hours) {
      let newHours = "";
      if (hours != null) {
        let hoursArr = hours.split(":");
        if (hoursArr[0].length == 1) {
          newHours = "00000" + hoursArr[0] + ":" + hoursArr[1];
        } else if (hoursArr[0].length == 2) {
          newHours = "0000" + hoursArr[0] + ":" + hoursArr[1];
        } else if (hoursArr[0].length == 3) {
          newHours = "000" + hoursArr[0] + ":" + hoursArr[1];
        } else if (hoursArr[0].length == 4) {
          newHours = "00" + hoursArr[0] + ":" + hoursArr[1];
        } else if (hoursArr[0].length == 5) {
          newHours = "0" + hoursArr[0] + ":" + hoursArr[1];
        } else {
          newHours = hoursArr[0] + ":" + hoursArr[1];
        }
        const validHHMMstring = (str) => /^([0-9][0-9][0-9][0-9][0-9][0-9]):[0-5][0-9]$/.test(str);
        return validHHMMstring(newHours);
      } else {
        return false
      }
    },

    async SavePilotExpToServer() {
      let dataAdd = {};
      if (this.formModelPex.fomAircraftModel.fom_acm_wing == "Rotary-Wing") {
        if (this.Validation(this.formModelPex.fom_pex_pic) && this.Validation(this.formModelPex.fom_pex_picus) && this.Validation(this.formModelPex.fom_pex_sic)) {
          dataAdd = {
            pl_id: this.pl_id,
            fom_acm_id: this.formModelPex.fomAircraftModel.fom_acm_id,
            fom_pex_pic: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_pic),
            fom_pex_picus: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_picus),
            fom_pex_sic: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_sic),
            fom_pex_total_type: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_total_type),
          };
        }
      } else if (this.formModelPex.fomAircraftModel.fom_acm_wing == "Fixed-Wing") {
        if (this.Validation(this.formModelPex.fom_pex_pic) && this.Validation(this.formModelPex.fom_pex_sic)) {
          dataAdd = {
            pl_id: this.pl_id,
            fom_acm_id: this.formModelPex.fomAircraftModel.fom_acm_id,
            fom_pex_pic: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_pic),
            fom_pex_picus: 0,
            fom_pex_sic: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_sic),
            fom_pex_total_type: this.$ConvertService.HHmmToMin(this.formModelPex.fom_pex_total_type),
          };
        }
      }
      await feathersClientUOA.service("fom-pilot-experience").create(dataAdd);
      this.dialogAddPilotEx = false;
      this.dialogSaveSuccess = true;
    }
  }
}
</script>

<style scoped>
.sr-only {
  color: black !important;
  font-size: 42px !important;
}

.B-display {
  width: 100%;
  height: 100%;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.custom-placeholer-color input::placeholder {
  color: yellowgreen !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: yellowgreen;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: yellowgreen !important;
}

table {
  color: black;
  font-size: 22px;
}

.th {
  border: solid;
  border-color: black;
  text-align: center;
}

td {
  border: solid;
  text-align: right;
  border-color: black;
  padding-left: 3px;
  padding-right: 3px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}
</style>